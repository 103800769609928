import { Inject, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NotificationService } from './notification.service';
import { TranslationService } from './translation.service';
import { CheckVersionService } from './check-version.service';
import { NotificationMessageType } from '../../shared/core';
import { ActionButtonType } from '../../shared/core';
import { WINDOW_OBJECT } from '../core.module';
import { AuthService } from './auth.service';
import { CheckTenantMismatchService } from './check-tenant-mismatch.service';

@Injectable({
  providedIn: 'root',
})
export class TenantMismatchService {
  showingPopup = false;

  constructor(
    @Inject(WINDOW_OBJECT) private window: Window,
    private notificationService: NotificationService,
    private checkTenantMismatchService: CheckTenantMismatchService,
    private authService: AuthService,
  ) {}

  initialize() {
    this.checkTenantMismatchService.hasTenantMismatch.subscribe({
      next: (hasTenantMismatch) => {
        if (hasTenantMismatch && !this.showingPopup) {
          this.showNotification();
        }
      },
    });
  }

  showNotification() {
    this.showingPopup = true;

    this.notificationService.showErrorDialog('tenantMismatch.message').subscribe({
      next: (result) => {
        if (result) {
          this.authService.getActiveTenant$().subscribe({
            next: (tenantId) => {
              this.window.location.href = `/t/${tenantId}`;
            },
          });
          this.showingPopup = false;
        }
      },
    });
  }
}
