<h1>tenant switch</h1>
<p>
  You have tried to access the resource
  <b>{{ redirectUrl }}</b>
  <ng-container *ngIf="urlTenantId">
    for tenant <b>{{ urlTenantId }}</b></ng-container
  >.
</p>

<p>NICE MESSAGE TO USER</p>

<button class="action-btn" mat-flat-button data-test-btn="proceedToResource" (click)="proceedToResource()">
  proceed to resource
</button>
