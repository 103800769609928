import { Version } from './version.model';

export const version: Version = {
  build: {
    time: '2024-09-16T09:14:17+0000',
  },
  git: {
    branchName: 'task/new-change-tenant',
    commitHash: '980e3e67a3afe3072b6d39b8b0ede06def6fe466',
  },
};
