import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { StorageObject } from '../../../../core';
import { AuthService } from '../../../../core/services/auth.service';
import { GlobalLoadingIndicatorService } from '../../../../core/services/global-loading-indicator.service';
import { STORAGE_OBJECT, URL_TENANT_ID, WINDOW_OBJECT } from '../../../../core/core.module';

import { filter, take } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './tenant-switch.component.html',
  styleUrls: ['./tenant-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TenantSwitchComponent {
  storage = inject(STORAGE_OBJECT);
  window = inject(WINDOW_OBJECT);
  urlTenantId = inject(URL_TENANT_ID);
  activatedRoute = inject(ActivatedRoute);

  redirectUrl = this.activatedRoute.snapshot.queryParamMap.get('redirectUrl');

  constructor(
    private authService: AuthService,
    private loadingIndicatorService: GlobalLoadingIndicatorService,
  ) {
    this.loadingIndicatorService.setLoading(false);
  }

  proceedToResource() {
    return this.authService.getTenants$().subscribe((allowedTenants) => {
      const urlTenant = allowedTenants.find((tenantOption) => tenantOption.id === this.urlTenantId);
      this.authService.login(this.redirectUrl?.replace(`/t/${urlTenant?.id}/`, '/'), urlTenant?.orgId);
    });
  }
}
