import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { StorageObject } from '../../../../core';
import { AuthService } from '../../../../core/services/auth.service';
import { GlobalLoadingIndicatorService } from '../../../../core/services/global-loading-indicator.service';
import { STORAGE_OBJECT, URL_TENANT_ID, WINDOW_OBJECT } from '../../../../core/core.module';

import { filter, take } from 'rxjs/operators';

@Component({
  templateUrl: './access-unauthorised.component.html',
  styleUrls: ['./access-unauthorised.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessUnauthorisedComponent {
  storage = inject(STORAGE_OBJECT) as StorageObject;
  window = inject(WINDOW_OBJECT) as Window;
  urlTenantId = inject(URL_TENANT_ID) as string;

  isAuthenticated$ = this.auth.isAuthenticated$;

  constructor(
    private auth: AuthService,
    private loadingIndicatorService: GlobalLoadingIndicatorService,
  ) {
    this.loadingIndicatorService.setLoading(false);
  }

  goToHome() {
    return this.auth
      .getTenants$()
      .pipe(
        take(1),
        filter(
          (tenants) => !this.urlTenantId || !tenants.map((tenantOption) => tenantOption.id).includes(this.urlTenantId),
        ),
      )
      .subscribe((tenants) => {
        this.window.location.href = `/t/${tenants[0]}`;
      });
  }

  logout() {
    this.auth.logout();
  }
}
