import { Inject, Injectable } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Version } from '../../../version/version.model';
import { catchError, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { SentryService } from './sentry.service';
import { AuthService } from './auth.service';
import { URL_TENANT_ID } from '../core.module';

@Injectable({
  providedIn: 'root',
})
export class CheckTenantMismatchService {
  readonly TIMEOUT = 1000 * 10;
  hasTenantMismatch: Observable<boolean>;

  constructor(
    private authService: AuthService,
    @Inject(URL_TENANT_ID) private urlTenantId: string,
  ) {
    this.hasTenantMismatch = timer(0, this.TIMEOUT).pipe(
      switchMap(() => this.authService.getActiveTenant$()),
      map((activeTenant) => {
        return urlTenantId !== undefined && activeTenant !== this.urlTenantId;
      }),
      filter((hasTenantMismatch) => hasTenantMismatch),
      take(1),
    );
  }
}
